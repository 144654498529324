import React, { useState } from "react";
import { Card, Form } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
});

const LoginForm = () => {
  const [requestState, setRequestState] = useState({
    sending: false,
    sent: false,
    success: false,
    error: false,
  });
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      setRequestState((prev) => ({ ...prev, sending: true }));

      setTimeout(() => {
        setRequestState((prev) => ({
          ...prev,
          sent: true,
          error: true,
          sending: false,
          success: false,
        }));
      }, 3000);
    },
  });

  return (
    <Form
      as={Card}
      style={{ minWidth: "300px", maxWidth: "500px", margin: "0 auto" }}
    >
      <Form.Group className="mb-3">
        {formik.touched.username && formik.errors.username && (
          <span style={{ color: "red", display: "block" }}>
            {formik.errors.username}
          </span>
        )}
        <Form.Label>Username</Form.Label>
        <Form.Control
          type="text"
          value={formik.values.username}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Enter username"
          name="username"
          id="username"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {formik.touched.password && formik.errors.password && (
          <span style={{ color: "red", display: "block" }}>
            {formik.errors.password}
          </span>
        )}
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Enter password"
          name="password"
          id="password"
        />
      </Form.Group>

      <div className="d-flex justify-content-center mt-3 flex-column">
        {requestState.error && (
          <span
            className="text-center px-2 mb-3 d-block"
            style={{ fontSize: "12px", color: "red" }}
          >
            This account does not exist.
          </span>
        )}
        <button
          className={`btn ${!requestState.sent && "btn-dark"} ${
            requestState.sent && requestState.success && "btn-success"
          } ${requestState.sent && requestState.error && "btn-danger"}`}
          type="submit"
          disabled={requestState.sent || requestState.sending}
          onClick={() => formik.handleSubmit()}
        >
          {requestState.sent && requestState.success
            ? "Success"
            : requestState.error
            ? "Error"
            : "Login"}
          {}
        </button>
      </div>
    </Form>
  );
};

export default LoginForm;
